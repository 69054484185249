import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchProfile = createAsyncThunk('profile/fetchProfile', async(_, thunkAPI) => {
  try {
    const response = await axios.get('/api/profile/');

    return response.data?.data ?? response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

// update profile
export const updateProfile = createAsyncThunk('profile/updateProfile', async(data, thunkAPI) => {
  try {
    const response = await axios.post('/api/profile/', data);

    return response.data?.data ?? response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

// update settings
export const updateUserSettings = createAsyncThunk('profile/updateUserSettings', async(data, thunkAPI) => {
  try {
    const response = await axios.post('/api/profile/settings/', data);

    return response.data?.data ?? response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

//reset password
export const resetPassword = createAsyncThunk('profile/resetPassword', async(data, thunkAPI) => {
  try {
    const response = await axios.post('/api/profile/password', {
      currentPassword: data.currentPassword,
      password: data.password,
      password_confirmation: data.password_confirmation,
    });

    // redirect to login page
    window.location.href = '/login';

    return response.data?.data ?? response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

const profileFeatureSlice = createSlice({
  name: 'profile',
  initialState: {
    currentTabIndex: 0,
    loading: false,
    error: null,
    passwordReset: {
      message: '',
      error: null,
    },
    profileData: {
      first_name: '',
      last_name: '',
      email: '',
      avatar: '',
      id: 0,
    },
    userSettingsData: {
      advanced_fields: false,
      course_tooltips: false,
      build_email_notifications: false,
      enable_ai_google_translate: false,
      silent_notifications: false,
      autosave_interval: 60,
      default_language: false,
      disable_clipboard_icon: false,
      new_features: true,
    },
  },
  reducers: {
    dismissPasswordResetError: (state) => {
      state.passwordReset.error = null;
    },
    dismissPasswordResetMessage: (state) => {
      state.passwordReset.message = '';
    },
    setProfileTabIndex: (state, action) => {
      state.currentTabIndex = action.payload;
    },
    dismissUserSettingsMessage: (state) => {
      state.userSettingsMessage = null;
    },
  },
  extraReducers: (builder) => {
    //fetch profile
    builder
      .addCase(fetchProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.password_is_expired = !!action.payload.password_is_expired;
        state.profileData = {
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
          email: action.payload.email,
          avatar: action.payload.avatar,
          id: action.payload.id,
        };
        state.userSettingsData = {
          advanced_fields: action.payload.advanced_fields,
          course_tooltips: action.payload.course_tooltips,
          build_email_notifications: action.payload.build_email_notifications,
          enable_ai_google_translate: action.payload.enable_ai_google_translate,
          silent_notifications: action.payload.silent_notifications,
          autosave_interval: action.payload.autosave_interval,
          default_language: action.payload.default_language,
          disable_clipboard_icon: action.payload.disable_clipboard_icon,
          new_features: action.payload.new_features,
        };
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    //update profile
    builder
      .addCase(updateProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.profileData = {
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
          email: action.payload.email,
          avatar: action.payload.avatar,
          id: action.payload.id,
        };
        state.userSettingsData = {
          advanced_fields: action.payload.advanced_fields,
          course_tooltips: action.payload.course_tooltips,
          build_email_notifications: action.payload.build_email_notifications,
          enable_ai_google_translate: action.payload.enable_ai_google_translate,
          silent_notifications: action.payload.silent_notifications,
          autosave_interval: action.payload.autosave_interval,
          default_language: action.payload.default_language,
          disable_clipboard_icon: action.payload.disable_clipboard_icon,
          new_features: action.payload.new_features,
        };
        // notify.alert('Profile updated successfully, page will refresh.', 'info', () => {
        //   window.location.reload();
        // });
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    //update user settings
    //update profile
    builder
      .addCase(updateUserSettings.pending, (state) => {
        state.loading = false;
        state.error = null;
        state.userSettingsSaving = true;
        state.userSettingsMessage = null;
      })
      .addCase(updateUserSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.userSettingsSaving = false;
        state.profileData = {
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
          email: action.payload.email,
          avatar: action.payload.avatar,
          id: action.payload.id,
        };
        (state.userSettingsMessage = {
          type: 'success',
          message: 'Settings updated successfully.',
        }),
        (state.userSettingsData = {
          advanced_fields: action.payload.advanced_fields,
          course_tooltips: action.payload.course_tooltips,
          build_email_notifications: action.payload.build_email_notifications,
          enable_ai_google_translate: action.payload.enable_ai_google_translate,
          silent_notifications: action.payload.silent_notifications,
          autosave_interval: action.payload.autosave_interval,
          default_language: action.payload.default_language,
          disable_clipboard_icon: action.payload.disable_clipboard_icon,
          new_features: action.payload.new_features,
        });
        // notify.alert('Settings updated successfully, page will refresh.', 'info', () => {
        //   window.location.reload();
        // });
      })
      .addCase(updateUserSettings.rejected, (state, action) => {
        state.userSettingsSaving = false;
        (state.userSettingsMessage = {
          type: 'error',
          message: 'Settings failed to update.',
        }),
        (state.error = action.payload);
      });
    //reset password
    builder
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.passwordReset.error = null;
        state.passwordReset.message = '';
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.passwordReset.message = 'Password reset successfully.';
        state.passwordReset.error = null;
        // notify.alert('Password reset successfully, page will refresh.', 'info', () => {
        //   window.location.reload();
        // });
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.passwordReset.error = action.payload;
        state.passwordReset.message = '';
      });
  },
});

export const {
  dismissPasswordResetError,
  dismissPasswordResetMessage,
  setProfileTabIndex,
  dismissUserSettingsMessage,
} = profileFeatureSlice.actions;

export default profileFeatureSlice.reducer;
