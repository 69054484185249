import React from 'react';
import PropTypes from 'prop-types';
import * as bgs from '../helpers/icon_bgs';
import * as check from '../helpers/icon_check';
import * as gen from '../helpers/icon_gen';
import * as int from '../helpers/icon_int';
import * as media from '../helpers/icon_media';
import * as quiz from '../helpers/icon_quiz';
import * as sort from '../helpers/icon_sort';
import * as tm from '../helpers/icon_topicmenu';

export default class AddSVG extends React.Component {
  constructor(props) {
    super(props);

    //14,23,35,79,110
    this.size = props.size;
    this.h = this.size == 110 ? 79 : this.resize == 86 ? 62 : this.size == 64 ? 46 : this.size == 35 ? 25 : this.size;
    this.w = this.size;
    this.box = '0 0 ' + this.w + ' ' + this.h;

    this.resize = props.resize;
    this.resizeH =
      this.resize == 110 ? 79 : this.resize == 86 ? 62 : this.resize == 64 ? 46 : this.resize == 35 ? 25 : this.resize;
    this.resizeW = this.resize;

    this.currIcon = this.currIcon.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  currIcon() {
    const iconStyle = {};
    const lineStyle = {};
    const pathStyle = {};
    const tipStyle = {};
    const textDashStyle = {};
    const props = this.props;
    if (props.fill) {
      iconStyle.fill = props.fill;
    }

    if (props.pathFill) {
      if (
        props.icon == 'add' ||
        props.icon == 'comp-anchortag' ||
        props.icon == 'books' ||
        props.icon == 'comp-bgimage' ||
        props.icon == 'comp-bgvideo' ||
        props.icon == 'comp-branchingsummary' ||
        props.icon == 'comp-button' ||
        props.icon == 'comp-audio' ||
        props.icon == 'comp-categorymatching' ||
        props.icon == 'comp-categorymatching-draggableL' ||
        props.icon == 'comp-categorymatching-draggableR' ||
        props.icon == 'comp-dragimagematching' ||
        props.icon == 'comp-cube' ||
        props.icon == 'comp-dragimagematching-draggableL' ||
        props.icon == 'comp-dragimagematching-draggableR' ||
        props.icon == 'comp-dragmatching' ||
        props.icon == 'comp-dragmatching-draggableL' ||
        props.icon == 'comp-dragmatching-draggableR' ||
        props.icon == 'comp-dragmediatomedia' ||
        props.icon == 'comp-dragmediatomedia-mediaL' ||
        props.icon == 'comp-dragmediatomedia-mediaR' ||
        props.icon == 'comp-dragtolabelimage' ||
        props.icon == 'comp-dragtolabelimage-mediaR' ||
        props.icon == 'comp-dragtolabelimage-mediaL' ||
        props.icon == 'comp-dragtolist' ||
        props.icon == 'comp-dragtolist-mediaL' ||
        props.icon == 'comp-dropdown' ||
        props.icon == 'comp-dragtolist-mediaR' ||
        props.icon == 'comp-formdatasubmit' ||
        props.icon == 'comp-fpo' ||
        props.icon == 'comp-freefallparallax' ||
        props.icon == 'comp-freefallparallax1' ||
        props.icon == 'comp-freefallparallax2' ||
        props.icon == 'comp-freefallparallax3' ||
        props.icon == 'comp-freefallparallax4' ||
        props.icon == 'comp-freefallparallax5' ||
        props.icon == 'comp-freefallparallax6' ||
        props.icon == 'comp-freefallparallax7' ||
        props.icon == 'comp-freefallparallax8' ||
        props.icon == 'comp-fullpagewebsite' ||
        props.icon == 'comp-fullwidthbgaccordion' ||
        props.icon == 'comp-gradparallax' ||
        props.icon == 'comp-hotspotclicktell' ||
        props.icon == 'comp-hotspotclicktell1' ||
        props.icon == 'comp-hotspotclicktell2' ||
        props.icon == 'comp-hotspotclicktell3' ||
        props.icon == 'comp-image' ||
        props.icon == 'comp-imagestack' ||
        props.icon == 'comp-memory' ||
        props.icon == 'comp-multcarousel' ||
        props.icon == 'comp-multicolumngridchecklist' ||
        props.icon == 'comp-panoramavr' ||
        props.icon == 'comp-peril' ||
        props.icon == 'comp-progressionclicktell1' ||
        props.icon == 'comp-progressionclicktell2' ||
        props.icon == 'comp-progressionguided' ||
        props.icon == 'comp-quickhitmintext' ||
        props.icon == 'comp-quickhittile' ||
        props.icon == 'comp-quizlistenuntimed' ||
        props.icon == 'comp-quiznonjudged' ||
        props.icon == 'comp-quiznonjudged-mediaL' ||
        props.icon == 'comp-quiznonjudged-mediaR' ||
        props.icon == 'comp-gallery' ||
        props.icon == 'comp-gallery1' ||
        props.icon == 'comp-gallery2' ||
        props.icon == 'comp-split3dcarousel' ||
        props.icon == 'comp-standardquiz' ||
        props.icon == 'comp-standardquiz1' ||
        props.icon == 'comp-standardquiz2' ||
        props.icon == 'comp-standardquiz3' ||
        props.icon == 'comp-table' ||
        props.icon == 'comp-thumbnailmosaicclicktell' ||
        props.icon == 'comp-thumbnailmosaicclicktell-horizontal-left' ||
        props.icon == 'comp-thumbnailmosaicclicktell-horizontal-right' ||
        props.icon == 'comp-thumbnailmosaicclicktell-vertical' ||
        props.icon == 'comp-thumbnailmosaicclicktell-vertical-multi' ||
        props.icon == 'comp-timelineHoriz' ||
        props.icon == 'comp-timelineVert' ||
        props.icon == 'comp-timelineVert1' ||
        props.icon == 'comp-timelineVert2' ||
        props.icon == 'comp-tipbutton' ||
        props.icon == 'comp-tipresponse' ||
        props.icon == 'comp-tipresponse1' ||
        props.icon == 'comp-tipresponse2' ||
        props.icon == 'comp-tipresponseoverview' ||
        props.icon == 'comp-video' ||
        props.icon == 'disclaimer' ||
        props.icon == 'eye' ||
        props.icon == 'faq' ||
        props.icon == 'graph' ||
        props.icon == 'lock' ||
        props.icon == 'no-eye' ||
        props.icon == 'objectives' ||
        props.icon == 'paint' ||
        props.icon == 'people' ||
        props.icon == 'subtract' ||
        props.icon == 'tag' ||
        props.icon == 'comp-truefalsegrid' ||
        props.icon == 'comp-wordfrenzy' ||
        props.icon == 'copytp'
      ) {
        pathStyle.fill = props.pathFill;
      } else if (props.icon == 'add-alt') {
        pathStyle.stroke = props.pathFill;
        pathStyle.fill = 'none';
        pathStyle.strokeWidth = '4';
        pathStyle.strokeLinecap = 'round';
        pathStyle.strokeMiterlimit = '10';

        iconStyle.stroke = props.pathFill;
        iconStyle.strokeWidth = '4';
        iconStyle.strokeLinecap = 'round';
        iconStyle.strokeMiterlimit = '10';
      } else {
        //multi-purpose
        iconStyle.stroke = props.pathFill;
      }
    }

    if (props.icon == 'comp-text_fields' || props.icon == 'topicpages') {
      pathStyle.fill = 'none';
      pathStyle.stroke = props.fill;
      textDashStyle.stroke = props.fill;
      textDashStyle.strokeDasharray = '1.4354,1.4354';
      if (props.icon == 'topicpages') {
        pathStyle.strokeWidth = '3';
      }
    } else if (props.icon == 'translate') {
      pathStyle.fill = 'none';
      pathStyle.stroke = props.pathFill;
      pathStyle.strokeWidth = '2.3';
    } else if (props.icon == 'box') {
      iconStyle.strokeWidth = '2.3';
    } else if (props.icon == 'disclaimer' || props.icon == 'pretest') {
      iconStyle.stroke = props.fill;
      iconStyle.strokeWidth = '2.3';
      iconStyle.fill = 'none';
      if (props.icon == 'pretest') {
        pathStyle.fill = 'none';
        pathStyle.stroke = props.fill;
        pathStyle.strokeWidth = '2.3';
      }
    } else if (props.icon == 'comp-tipbutton' || props.icon == 'comp-tipresponse2') {
      tipStyle.fill = props.pathFill;
      tipStyle.stroke = props.fill;
      tipStyle.strokeWidth = '1.5573';
    } else if (props.icon == 'comp-tipresponseoverview' || props.icon == 'comp-table') {
      iconStyle.stroke = 0;
      pathStyle.stroke = props.fill;
      pathStyle.strokeWidth = '2.3';
    } else if (props.icon == 'close') {
      iconStyle.stroke = props.fill;
    } else if (props.icon == 'comp-branchingsummary') {
      lineStyle.fill = 'none';
      lineStyle.stroke = props.pathFill;
      lineStyle.strokeWidth = '2.3';
    }

    if (props.icon === 'comp-dropdown') {
      //   console.log('checking', pathStyle);
    }

    switch (props.icon) {
    //GENERAL
    case 'actions':
      return gen.svgActions(iconStyle);
    case 'add':
      return gen.svgAdd(iconStyle, pathStyle);
    case 'add-alt':
      return gen.svgAddAlt(iconStyle, pathStyle);
    case 'admin':
      return gen.svgAdmin(iconStyle);
    case 'archive':
      return gen.svgArchive(iconStyle, pathStyle);
    case 'assets':
      return gen.svgAssets(iconStyle);
    case 'books':
      return gen.svgBooks(iconStyle);
    case 'box':
      return gen.svgBox(iconStyle);
    case 'certificate':
      return gen.svgCertificate(iconStyle);
    case 'clients':
      return gen.svgClients(iconStyle);
    case 'close':
      return gen.svgClose(iconStyle);
    case 'cloud':
      return gen.svgCloud(iconStyle);
    case 'collapse':
      return gen.svgCollapse(iconStyle);
    case 'commonterms':
      return gen.svgCommonTerms(iconStyle);
    case 'copy':
      return gen.svgCopy(iconStyle);
    case 'copytp':
      return gen.svgCopyTP(iconStyle, pathStyle);
    case 'disclaimer':
      return gen.svgDisclaimer(iconStyle, pathStyle);
    case 'dots3':
      return gen.svgDots3(iconStyle);
    case 'download':
      return gen.svgDownload(iconStyle);
    case 'downloadAlt1':
      return gen.svgDownloadAlt1(iconStyle);
    case 'downloadcode':
      return gen.svgDownloadCode(iconStyle);
    case 'drag':
      return gen.svgDrag(iconStyle);
    case 'edit':
      return gen.svgEdit(iconStyle);
    case 'editAlt1':
      return gen.svgEditAlt1(iconStyle);
    case 'export':
      return gen.svgExport(iconStyle);
    case 'eye':
      return gen.svgEye(iconStyle);
    case 'eyeAlt1':
      return gen.svgEyeAlt1(iconStyle);
    case 'faq':
      return gen.svgFAQ(iconStyle);
    case 'folder':
      return gen.svgFolder(iconStyle);
    case 'glossary':
      return gen.svgGlossary(iconStyle);
    case 'graph':
      return gen.svgGraph(iconStyle);
    case 'home':
      return gen.svgHome(iconStyle);
    case 'lock':
      return gen.svgLock(iconStyle);
    case 'magnify':
      return gen.svgMagnify(iconStyle);
    case 'microphone':
      return gen.svgMicrophone(iconStyle);
    case 'no-eye':
      return gen.svgNoEye(iconStyle, pathStyle);
    case 'objectives':
      return gen.svgObjectives(iconStyle);
    case 'paint':
      return gen.svgPaint(iconStyle);
    case 'people':
      return gen.svgPeople(iconStyle);
    case 'permissions':
      return gen.svgPermissions(iconStyle);
    case 'pin':
      return gen.svgPin(iconStyle);
    case 'pretest':
      return gen.svgPreTest(iconStyle, pathStyle);
    case 'preview':
      return gen.svgPreview(iconStyle);
    case 'previewAlt1':
      return gen.svgPreviewAlt1(iconStyle);
    case 'profile':
      return gen.svgProfile(iconStyle);
    case 'replace':
      return gen.svgReplace(iconStyle);
    case 'resize':
      return gen.svgResize(iconStyle);
    case 'resources':
      return gen.svgResources(iconStyle);
    case 'rocket':
      return gen.svgRocket(iconStyle);
    case 'roles':
      return gen.svgRoles(iconStyle);
    case 'save':
      return gen.svgSave(iconStyle);
    case 'sessions':
      return gen.svgSessions(iconStyle);
    case 'settings':
      return gen.svgSettings(iconStyle);
    case 'settings-outline':
      return gen.svgSettingsOutline(iconStyle);
    case 'spotlight':
      return gen.svgSpotlight(iconStyle);
    case 'subtract':
      return gen.svgSubtract(iconStyle, pathStyle);
    case 'table':
      return gen.svgTable(iconStyle, pathStyle);
    case 'tag':
      return gen.svgTag(iconStyle);
    case 'topicpages':
      return gen.svgTopicPages(pathStyle);
    case 'translate':
      return gen.svgTranslate(pathStyle);
    case 'trash':
      return gen.svgTrash(iconStyle);
    case 'unplugged':
      return gen.svgUnplugged(iconStyle);
    case 'upload':
      return gen.svgUpload(iconStyle);
    case 'uploadAlt1':
      return gen.svgUploadAlt1(iconStyle);
    case 'users':
      return gen.svgUsers(iconStyle);

      //COMPONENTS -- TOPIC MENU
    case 'comp-topicmenuverticallist':
      return tm.svgVerticalList(iconStyle);
    case 'comp-topicmenuhorizontalmedia':
      return tm.svgHorizontalMedia(iconStyle);
    case 'comp-topicmenuverticalmedia':
      return tm.svgVerticalMedia(iconStyle);

      //COMPONENTS -- BACKGROUNDS
    case 'comp-bgcolor':
      return bgs.svgBgColor(iconStyle);
    case 'comp-bgimage':
      return bgs.svgBgImage(iconStyle, pathStyle);
    case 'comp-bgvideo':
      return bgs.svgBgVideo(iconStyle, pathStyle);

      //COMPONENTS -- CHECKPOINTS & GAMES
    case 'comp-formdatasubmit':
      return check.svgFormDataSubmit(iconStyle, pathStyle);
    case 'comp-multicolumngridchecklist':
      return check.svgMultiColumnGridChecklist(iconStyle, pathStyle);
    case 'comp-truefalsegrid':
      return check.svgTrueFalseGrid(iconStyle, pathStyle);
    case 'comp-quiznonjudged':
      return check.svgQuizNonJudgedMediaL(iconStyle, pathStyle);
    case 'comp-quiznonjudged-mediaL':
      return check.svgQuizNonJudgedMediaL(iconStyle, pathStyle);
    case 'comp-quiznonjudged-mediaR':
      return check.svgQuizNonJudgedMediaR(iconStyle, pathStyle);

      //COMPONENTS -- GENERAL
    case 'comp-anchortag':
      return gen.svgAnchorTag(iconStyle, pathStyle);
    case 'comp-button':
      return gen.svgButton(iconStyle, pathStyle);
    case 'comp-dropdown':
      return gen.svgDropDown(iconStyle, pathStyle);
    case 'comp-freefallparallax':
      return gen.svgFreefallParallax(pathStyle, iconStyle);
    case 'comp-freefallparallax1':
      return gen.svgFreefallParallax(pathStyle, iconStyle);
    case 'comp-freefallparallax2':
      return gen.svgFreefallParallax2(pathStyle, iconStyle);
    case 'comp-freefallparallax3':
      return gen.svgFreefallParallax3(pathStyle, iconStyle);
    case 'comp-freefallparallax4':
      return gen.svgFreefallParallax4(pathStyle, iconStyle);
    case 'comp-freefallparallax5':
      return gen.svgFreefallParallax5(pathStyle, iconStyle);
    case 'comp-freefallparallax6':
      return gen.svgFreefallParallax6(pathStyle, iconStyle);
    case 'comp-freefallparallax7':
      return gen.svgFreefallParallax7(pathStyle, iconStyle);
    case 'comp-freefallparallax8':
      return gen.svgFreefallParallax8(pathStyle, iconStyle);
    case 'comp-fullpagewebsite':
      return gen.svgFullpageWebsite(pathStyle, iconStyle);
    case 'comp-htmlize':
      return gen.svgHTML(iconStyle, pathStyle);
    case 'comp-imagestack':
      return gen.svgImageStack(iconStyle, pathStyle);
    case 'comp-pinnedmosaic':
      return gen.svgPinnedImageMosaic14(iconStyle);
    case 'comp-pinnedmosaic1':
      return gen.svgPinnedImageMosaic1(iconStyle);
    case 'comp-pinnedmosaic2':
      return gen.svgPinnedImageMosaic2(iconStyle);
    case 'comp-pinnedmosaic3':
      return gen.svgPinnedImageMosaic3(iconStyle);
    case 'comp-pinnedmosaic4':
      return gen.svgPinnedImageMosaic4(iconStyle);
    case 'comp-pinnedmosaic5':
      return gen.svgPinnedImageMosaic5(iconStyle);
    case 'comp-pinnedmosaic6':
      return gen.svgPinnedImageMosaic6(iconStyle);
    case 'comp-pinnedmosaic7':
      return gen.svgPinnedImageMosaic7(iconStyle);
    case 'comp-pinnedmosaic8':
      return gen.svgPinnedImageMosaic8(iconStyle);
    case 'comp-pinnedmosaic9':
      return gen.svgPinnedImageMosaic9(iconStyle);
    case 'comp-pinnedmosaic10':
      return gen.svgPinnedImageMosaic10(iconStyle);
    case 'comp-pinnedmosaic11':
      return gen.svgPinnedImageMosaic11(iconStyle);
    case 'comp-pinnedmosaic12':
      return gen.svgPinnedImageMosaic12(iconStyle);
    case 'comp-pinnedmosaic13':
      return gen.svgPinnedImageMosaic13(iconStyle);
    case 'comp-pinnedmosaic14':
      return gen.svgPinnedImageMosaic14(iconStyle);
    case 'comp-pinnedmosaic15':
      return gen.svgPinnedImageMosaic15(iconStyle);
    case 'comp-pinnedmosaic16':
      return gen.svgPinnedImageMosaic16(iconStyle);
    case 'comp-separator':
      return gen.svgSeparator(iconStyle);
    case 'comp-table':
      return gen.svgTable(iconStyle, pathStyle);
    case 'comp-timelineVert':
      return gen.svgTimelineVertical(iconStyle, pathStyle);
    case 'comp-timelineVert1':
      return gen.svgTimelineVertical1(iconStyle, pathStyle);
    case 'comp-timelineVert2':
      return gen.svgTimelineVertical2(iconStyle, pathStyle);
    case 'comp-poll':
      return gen.svgPoll(iconStyle);

      //COMPONENTS -- INTERACTIONS
    case 'comp-accordion':
      return int.svgAccordion(iconStyle);
    case 'comp-accordion-none':
      return int.svgAccordionNone(iconStyle);
    case 'comp-accordion-mediaL':
      return int.svgAccordion(iconStyle);
    case 'comp-accordion-mediaR':
      return int.svgAccordionMediaR(iconStyle);
    case 'comp-accordion-zoomL':
      return int.svgAccordionZoomL(iconStyle);
    case 'comp-accordion-zoomR':
      return int.svgAccordionZoomR(iconStyle);
    case 'comp-attestation':
      return int.svgAttestation(iconStyle);
    case 'comp-cube':
      return int.svgCube(iconStyle, pathStyle);
    case 'comp-fullwidthbgaccordion':
      return int.svgFullWidthBgAccordion(iconStyle, pathStyle);
    case 'comp-gradparallax':
      return int.svgGraduatedParallax(iconStyle, pathStyle);
    case 'comp-hotspotclicktell':
      return int.svgHotspotClicktell1(iconStyle, pathStyle);
    case 'comp-hotspotclicktell1':
      return int.svgHotspotClicktell1(iconStyle, pathStyle);
    case 'comp-hotspotclicktell2':
      return int.svgHotspotClicktell2(iconStyle, pathStyle);
    case 'comp-hotspotclicktell3':
      return int.svgHotspotClicktell3(iconStyle, pathStyle);
    case 'comp-multcarousel':
      return int.svgMultipleImageCarousel(iconStyle, pathStyle);
    case 'comp-panoramavr':
      return int.svgPanoramavr(iconStyle, pathStyle);
    case 'comp-progressionclicktell1':
      return int.svgProgressionClicktell(iconStyle, pathStyle);
    case 'comp-progressionclicktell2':
      return int.svgProgressionClicktell2(iconStyle, pathStyle);
    case 'comp-progressionguided':
      return int.svgProgressionGuided(iconStyle, pathStyle);
    case 'comp-quickhittile':
      return int.svgQuickHitTile(iconStyle, pathStyle);
    case 'comp-quickhitmintext':
      return int.svgQuickHitMinTextClicktell(iconStyle);
    case 'comp-quickhitmintext-mediaR':
      return int.svgQuickHitMinTextClicktell(iconStyle);
    case 'comp-quickhitmintext-mediaL':
      return int.svgQuickHitMinTextClicktell2(iconStyle);
    case 'comp-quickhitmintext-noExtMedia':
      return int.svgQuickHitMinTextNoExtMedia(iconStyle);
    case 'comp-split3dcarousel':
      return int.svgSplit3dCarousel(iconStyle,pathStyle);
    case 'comp-gallery':
      return int.svgSlideshowGallery2(iconStyle, pathStyle);
    case 'comp-gallery1':
      return int.svgSlideshowGallery1(iconStyle, pathStyle);
    case 'comp-gallery2':
      return int.svgSlideshowGallery2(iconStyle, pathStyle);
    case 'comp-tipbutton':
      return int.svgTipButton(iconStyle, pathStyle, tipStyle);
    case 'comp-tab':
      return int.svgTab(iconStyle);
    case 'comp-tab1':
      return int.svgTab(iconStyle);
    case 'comp-tab2':
      return int.svgTabVertical(iconStyle);
    case 'comp-thumbnailmosaicclicktell':
      return int.svgThumbnailMosaicClicktellVertical(iconStyle, pathStyle);
    case 'comp-thumbnailmosaicclicktell-horizontal-left':
      return int.svgThumbnailMosaicClicktellHorizontalLeft(iconStyle, pathStyle);
    case 'comp-thumbnailmosaicclicktell-horizontal-right':
      return int.svgThumbnailMosaicClicktellHorizontalRight(iconStyle, pathStyle);
    case 'comp-thumbnailmosaicclicktell-vertical':
      return int.svgThumbnailMosaicClicktellVertical(iconStyle, pathStyle);
    case 'comp-thumbnailmosaicclicktell-vertical-multi':
      return int.svgThumbnailMosaicClicktellVerticalMulti(iconStyle, pathStyle);
    case 'comp-timelineHoriz':
      return int.svgTimelineHorizontal(iconStyle, pathStyle);
    case 'comp-timelineVisual1':
      return int.svgTimelineVisual1(iconStyle, pathStyle);
    case 'comp-timelineVisual2':
      return int.svgTimelineVisual2(iconStyle, pathStyle);
    case 'comp-tipresponse':
      return int.svgTipResponse(iconStyle, pathStyle);
    case 'comp-tipresponse1':
      return int.svgTipResponse(iconStyle, pathStyle);
    case 'comp-tipresponse2':
      return int.svgTipResponse2(iconStyle, pathStyle, tipStyle);
    case 'comp-tipresponseoverview':
      return int.svgTipResponseOverview(iconStyle, pathStyle);

      //COMPONENTS -- MEDIA
    case 'comp-adobeanimate':
      return media.svgAdobeAnimate(iconStyle);
    case 'comp-audio':
      return media.svgAudio(iconStyle, pathStyle);
    case 'comp-beforeafter':
      return media.svgBeforeAfter(iconStyle);
    case 'comp-embed':
      return media.svgEmbed(iconStyle);
    case 'comp-fpo':
      return media.svgFPO(iconStyle, pathStyle);
    case 'comp-image':
      return media.svgImage(iconStyle, pathStyle);
    case 'comp-panorama':
      return media.svgPanorama(iconStyle);
    case 'comp-pullquote':
      return media.svgPullquote(iconStyle);
    case 'comp-text_fields':
      return media.svgText(iconStyle, pathStyle, textDashStyle);
    case 'comp-timedcarousel':
      return media.svgTimedCarousel(iconStyle);
    case 'comp-video':
      return media.svgVideo(iconStyle, pathStyle);
    case 'comp-zoom':
      return media.svgZoom(iconStyle);

      //COMPONENTS -- SORTABLES
    case 'comp-categorymatching':
      return sort.svgCategoryMatchingL(iconStyle, pathStyle);
    case 'comp-categorymatching-draggableL':
      return sort.svgCategoryMatchingL(iconStyle, pathStyle);
    case 'comp-categorymatching-draggableR':
      return sort.svgCategoryMatchingR(iconStyle, pathStyle);
    case 'comp-dragmatching':
      return sort.svgDragMatchingL(iconStyle, pathStyle);
    case 'comp-dragmatching-draggableL':
      return sort.svgDragMatchingL(iconStyle, pathStyle);
    case 'comp-dragmatching-draggableR':
      return sort.svgDragMatchingR(iconStyle, pathStyle);
    case 'comp-dragimagematching':
      return sort.svgDragImageMatchingL(iconStyle, pathStyle);
    case 'comp-dragimagematching-draggableL':
      return sort.svgDragImageMatchingL(iconStyle, pathStyle);
    case 'comp-dragimagematching-draggableR':
      return sort.svgDragImageMatchingR(iconStyle, pathStyle);
    case 'comp-dragmediatomedia':
      return sort.svgDragMediaToMediaL(iconStyle, pathStyle);
    case 'comp-dragmediatomedia-mediaL':
      return sort.svgDragMediaToMediaL(iconStyle, pathStyle);
    case 'comp-dragmediatomedia-mediaR':
      return sort.svgDragMediaToMediaR(iconStyle, pathStyle);
    case 'comp-dragtolabelimage':
      return sort.svgDragToLabelImageMediaR(iconStyle, pathStyle);
    case 'comp-dragtolabelimage-mediaR':
      return sort.svgDragToLabelImageMediaR(iconStyle, pathStyle);
    case 'comp-dragtolabelimage-mediaL':
      return sort.svgDragToLabelImageMediaL(iconStyle, pathStyle);
    case 'comp-dragtolist':
      return sort.svgDragToListMediaR(iconStyle, pathStyle);
    case 'comp-dragtolist-mediaL':
      return sort.svgDragToListMediaL(iconStyle, pathStyle);
    case 'comp-dragtolist-mediaR':
      return sort.svgDragToListMediaR(iconStyle, pathStyle);
    case 'comp-accordion-dragToOrder':
      return sort.svgAccordionDragToOrder(iconStyle);

      //COMPONENTS -- QUIZ
    case 'comp-branchingsummary':
      return quiz.svgBranchingSummary(iconStyle, pathStyle, lineStyle);
    case 'comp-memory':
      return quiz.svgMemory(iconStyle, pathStyle);
    case 'comp-peril':
      return quiz.svgPeril(iconStyle, pathStyle);
    case 'comp-quizlistenuntimed':
      return quiz.svgQuizListenUntimed(iconStyle, pathStyle);
    case 'comp-scratchreveal':
      return quiz.svgScratchReveal(iconStyle, pathStyle);
    case 'comp-standardquiz':
      return quiz.svgStandardQuiz(iconStyle, pathStyle);
    case 'comp-standardquiz1':
      return quiz.svgStandardQuiz(iconStyle, pathStyle);
    case 'comp-standardquiz2':
      return quiz.svgStandardQuiz2(iconStyle, pathStyle);
    case 'comp-standardquiz3':
      return quiz.svgStandardQuiz3(iconStyle, pathStyle);
    case 'comp-wordfrenzy':
      return quiz.svgWordFrenzy(iconStyle, pathStyle);

    default:
      return null;
    }
  }

  render() {
    const svgStyle = {};
    svgStyle.height = this.resizeH;
    svgStyle.minWidth = this.resizeW;
    svgStyle.width = this.resizeW;

    if (this.props.icon == 'copytp') {
      svgStyle.marginLeft = '2px';
    }

    return (
      <svg
        style={svgStyle}
        enableBackground={this.box}
        height={this.h}
        preserveAspectRatio='none'
        width={this.w}
        x='0'
        xlinkHref='http://www.w3.org/1999/xlink'
        xmlns='http://www.w3.org/2000/svg'
        xmlSpace='preserve'
        y='0'
        viewBox={this.box}
      >
        {this.currIcon()}
      </svg>
    );
  }
}

AddSVG.propTypes = {
  icon: PropTypes.string,
  resize: PropTypes.number,
  size: PropTypes.number,
};
