import React, { useState } from 'react';
import './NewCoolFeatures.scss';

const NewCoolFeatures = props => {
  const [activeTab, setActiveTab] = useState( 'new' );
  const [activeDesc, setActiveDesc] = useState( -1 );

  const newFeatures = [
    {
      title: 'Visual Timeline Click-tell Component',
      desc: '<p>New to our lineup of linear click-tells is a more visual approach to a timeline. There are plenty of styling and formatting options available to make your content pop in this new format. You can preview this component in the <a target="_blank" href="https://chameleon-4-prod.s3.amazonaws.com/clients/2-637e57a28b15d4/courses/116-63c956e2775d9/pre/index.html#/en-US/*/lesson/21/6">Page Layout Demo</a>.</p>',
    },
    {
      title: 'Panorama VR Component',
      desc: '<p>The new Panorama VR component opens up immersive learning possibilities. You can explore a demo example in the <a target="_blank" href="https://chameleon-4-prod.s3.amazonaws.com/clients/2-637e57a28b15d4/courses/116-63c956e2775d9/pre/index.html#/en-US/*/lesson/21/7">Page Layout Demo</a>.</p>',
    },
    {
      title: 'Component Selection Search',
      desc: "<p>New to the component selector is a Search field added above the category tabs. It currently searches based on component names, but in the future, we'll be able to add additional tags that will help in case you don't quite know what you're looking for. Here is a <a target=\"_blank\" href=\"https://chameleon-4-prod.s3.amazonaws.com/clients/2-637e57a28b15d4/courses/117-63c97189d1cf8/pre/course/media/resources/file-672ce32092796.pdf\">Component Selector tool</a> you can refer back to any time.</p>",
    },
  ];
  const coolFeatures = [
    {
      title: 'Editor Language Preference',
      desc: "<p>The editor now allows you to edit your courses in nearly any language you want. To change your editor's language, go to the Chameleon homepage, click on Profile and then click on user Settings. There, you can change your editor language from the English default to the language of your choice.</p>",
    },
    {
      title: 'Copy/Paste Single Row',
      desc: "<p>Have you ever spent a bunch of time customizing row settings on a page – changing margins and padding, customizing the animations and spacing of text, etc. – or customizing the component within the row – and wanted to copy those customizations to another location on the same page or somewhere else in your course? It's now possible thanks to the new copy row button available in the layout workspace.</p><p>When you click the copy row icon (highlighted in the 1st image you see here), you'll see a change to the layout panel – the new Paste Row buttons display between each current row on all pages in the course (2nd image if you drag the arrow slider over to the left). You just pick the placement you want, select the Paste Row button, and your copied row displays. Carefully consider both the content you're copying and its destination, as incorrect placement could disrupt page functionality.</p>",
    },
  ];

  const clickTab = ( tabName ) => {
    setActiveDesc(-1);
    setActiveTab(tabName);
  };

  return (
    <div className="NewCoolFeatures">
      {/* <div className="nav"><button className={`cml-button cml-button--secondary ${activeTab === "cool" ? "cml-button--inverse" : ""} cml-button--default`} onClick={()=>{clickTab('new')}}>New Features</button><button className={`cml-button cml-button--secondary ${activeTab === "new" ? " cml-button--inverse" : ""} cml-button--default`} onClick={()=>{clickTab('cool')}}>Cool Features</button></div> */}
      <div className="nav"><button className={`${activeTab === 'new' ? 'active' : ''}`} onClick={() => {clickTab('new');}}>New Features</button> | <button className={`${activeTab === 'cool' ? 'active' : ''}`} onClick={() => {clickTab('cool');}}>Cool Features</button></div>
      <div className="features">
        {
          activeTab === 'new' ?
            newFeatures.map((feat, featIdx) => {
              return (
                <div className="feature" key={`feature-${featIdx}`}>
                  <a href="#" className={activeDesc === featIdx ? 'active' : ''} onClick={(e) => {e.preventDefault(); setActiveDesc(featIdx);}}>{feat.title}</a>
                  {activeDesc === featIdx && <div className="description" dangerouslySetInnerHTML={ { __html: feat.desc } } />}
                </div>
              );
            })
            :
            coolFeatures.map((feat, featIdx) => {
              return (
                <div className="feature" key={`feature-${featIdx}`}>
                  <a href="#" className={activeDesc === featIdx ? 'active' : ''} onClick={(e) => {e.preventDefault(); setActiveDesc(featIdx);}}>{feat.title}</a>
                  {activeDesc === featIdx && <div className="description" dangerouslySetInnerHTML={ { __html: feat.desc } } />}
                </div>
              );
            })
        }
      </div>
    </div>
  );
};

export default NewCoolFeatures;
