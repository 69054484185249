import React from 'react';
import './AccordionButton.scss';

const AccordionButton = (props) => {
  const { val, onClick, visualOnly } = props;

  function isRelevantKey(e) {
    const relevantKeys = [' ', 'Spacebar', 'Enter'];

    return relevantKeys.includes(e.key);
  }

  if (visualOnly) {
    return (
      <div className='AccordionButton'>
        <div className={`caret ${val ? 'close' : 'open'}`}>
          <div className={'text'}> {val ? 'Close' : 'Open'}</div>
        </div>
      </div>
    );
  }

  return (
    <div
      className='AccordionButton'
      onClick={onClick}
      tabIndex={props?.tabIndex ?? 0}
      onKeyUp={(e) => {
        if (isRelevantKey(e)) {
          onClick();
        }
      }}
    >
      <div className={`caret ${val ? 'close' : 'open'}`}>
        <div className={'text'}> {val ? 'Close' : 'Open'}</div>
      </div>
    </div>
  );
};

export default AccordionButton;
