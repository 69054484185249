import React from 'react';
import './PageTitle.scss';

const PageTitle = (props) => {
  const { title = '', description = '', jsx, secondary = false, flex = null } = props;

  let displayDescription = description;
  if (props.text) {
    displayDescription = props.text;
  }

  return (
    <div className={`PageTitle ${secondary ? 'secondary' : ''}`}>
      <div className={'group'}>
        {title && <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>}
        {displayDescription && <p dangerouslySetInnerHTML={{ __html: displayDescription }}></p>}
        {jsx ?? null}
      </div>
      <div className={'group'}>{flex ?? null}</div>
    </div>
  );
};

export default PageTitle;
