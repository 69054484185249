import React from 'react';
import './SVGIcon.scss';

const SVGIcon = (props) => {
  const { type, size, fill, style } = props;

  //default style

  const styles = {
    width: size ?? 24,
    height: size ?? 24,
    fill: fill || '#000',
    pathFill: fill || '#fff',
    ...(style ?? {}),
  };

  const attributes = {
    style: styles,
    'aria-label': `${type} icon`,
  };

  const icons = {
    send: (
      <svg
        {...attributes}
        xmlns='http://www.w3.org/2000/svg'
        height='24px'
        viewBox='0 -960 960 960'
        width='24px'
        fill='#e8eaed'
      >
        <path d='M120-160v-640l760 320-760 320Zm80-120 474-200-474-200v140l240 60-240 60v140Zm0 0v-400 400Z' />
      </svg>
    ),
    chatOpen: (
      <svg
        {...attributes}
        xmlns='http://www.w3.org/2000/svg'
        height='24px'
        viewBox='0 -960 960 960'
        width='24px'
        fill='#e8eaed'
      >
        <path d='M80-80v-720q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H240L80-80Zm126-240h594v-480H160v525l46-45Zm-46 0v-480 480Z' />
      </svg>
    ),
    chat: (
      <svg
        {...attributes}
        xmlns='http://www.w3.org/2000/svg'
        height='24px'
        viewBox='0 -960 960 960'
        width='24px'
        fill='#e8eaed'
      >
        <path d='M240-400h320v-80H240v80Zm0-120h480v-80H240v80Zm0-120h480v-80H240v80ZM80-80v-720q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H240L80-80Zm126-240h594v-480H160v525l46-45Zm-46 0v-480 480Z' />
      </svg>
    ),
    help: (
      <svg
        {...attributes}
        xmlns='http://www.w3.org/2000/svg'
        height='24px'
        viewBox='0 -960 960 960'
        width='24px'
        fill='#e8eaed'
      >
        <path d='M478-240q21 0 35.5-14.5T528-290q0-21-14.5-35.5T478-340q-21 0-35.5 14.5T428-290q0 21 14.5 35.5T478-240Zm-36-154h74q0-33 7.5-52t42.5-52q26-26 41-49.5t15-56.5q0-56-41-86t-97-30q-57 0-92.5 30T342-618l66 26q5-18 22.5-39t53.5-21q32 0 48 17.5t16 38.5q0 20-12 37.5T506-526q-44 39-54 59t-10 73Zm38 314q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z' />
      </svg>
    ),
    question: (
      <svg
        {...attributes}
        xmlns='http://www.w3.org/2000/svg'
        height='24px'
        viewBox='0 -960 960 960'
        width='24px'
        fill='#e8eaed'
      >
        <path d='M424-320q0-81 14.5-116.5T500-514q41-36 62.5-62.5T584-637q0-41-27.5-68T480-732q-51 0-77.5 31T365-638l-103-44q21-64 77-111t141-47q105 0 161.5 58.5T698-641q0 50-21.5 85.5T609-475q-49 47-59.5 71.5T539-320H424Zm56 240q-33 0-56.5-23.5T400-160q0-33 23.5-56.5T480-240q33 0 56.5 23.5T560-160q0 33-23.5 56.5T480-80Z' />
      </svg>
    ),
    drag: (
      <svg
        {...attributes}
        xmlns='http://www.w3.org/2000/svg'
        height='24px'
        viewBox='0 -960 960 960'
        width='24px'
        fill='#e8eaed'
      >
        <path d='M480-80 310-250l57-57 73 73v-206H235l73 72-58 58L80-480l169-169 57 57-72 72h206v-206l-73 73-57-57 170-170 170 170-57 57-73-73v206h205l-73-72 58-58 170 170-170 170-57-57 73-73H520v205l72-73 58 58L480-80Z' />
      </svg>
    ),
    review: (
      <svg
        {...attributes}
        xmlns='http://www.w3.org/2000/svg'
        height='24px'
        viewBox='0 -960 960 960'
        width='24px'
        fill='#e8eaed'
      >
        <path d='M40-160v-160q0-34 23.5-57t56.5-23h131q20 0 38 10t29 27q29 39 71.5 61t90.5 22q49 0 91.5-22t70.5-61q13-17 30.5-27t36.5-10h131q34 0 57 23t23 57v160H640v-91q-35 25-75.5 38T480-200q-43 0-84-13.5T320-252v92H40Zm440-160q-38 0-72-17.5T351-386q-17-25-42.5-39.5T253-440q22-37 93-58.5T480-520q63 0 134 21.5t93 58.5q-29 0-55 14.5T609-386q-22 32-56 49t-73 17ZM160-440q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T280-560q0 50-34.5 85T160-440Zm640 0q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T920-560q0 50-34.5 85T800-440ZM480-560q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T600-680q0 50-34.5 85T480-560Z' />
      </svg>
    ),
    download: (
      <svg {...attributes} xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'>
        <path d='M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z' />
      </svg>
    ),
    upload: (
      <svg {...attributes} xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'>
        <path d='M440-320v-326L336-542l-56-58 200-200 200 200-56 58-104-104v326h-80ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z' />
      </svg>
    ),
    delete: (
      <svg {...attributes} xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'>
        <path d='M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z' />
      </svg>
    ),
    undo: (
      <svg {...attributes} xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'>
        <path d='M280-200v-80h284q63 0 109.5-40T720-420q0-60-46.5-100T564-560H312l104 104-56 56-200-200 200-200 56 56-104 104h252q97 0 166.5 63T800-420q0 94-69.5 157T564-200H280Z' />
      </svg>
    ),
    flag: (
      <svg
        {...attributes}
        xmlns='http://www.w3.org/2000/svg'
        height='24px'
        viewBox='0 -960 960 960'
        width='24px'
        fill='#e8eaed'
      >
        <path d='M200-120v-680h360l16 80h224v400H520l-16-80H280v280h-80Zm300-440Zm86 160h134v-240H510l-16-80H280v240h290l16 80Z' />
      </svg>
    ),
    flagCheck: (
      <svg
        {...attributes}
        xmlns='http://www.w3.org/2000/svg'
        height='24px'
        viewBox='0 -960 960 960'
        width='24px'
        fill='#e8eaed'
      >
        <path d='M500-560ZM200-120v-680h250q-5 20-8 40t-2 40H280v240h290l16 80h134v-46q20 0 40-3t40-9v138H520l-16-80H280v280h-80Zm491-516 139-138-42-42-97 95-39-39-42 43 81 81Zm29-290q83 0 141.5 58.5T920-726q0 83-58.5 141.5T720-526q-83 0-141.5-58.5T520-726q0-83 58.5-141.5T720-926Z' />
      </svg>
    ),
    ai: (
      <svg
        {...attributes}
        xmlns='http://www.w3.org/2000/svg'
        height='24px'
        viewBox='0 -960 960 960'
        width='24px'
        fill='#e8eaed'
      >
        <path d='M323-160q-11 0-20.5-5.5T288-181l-78-139h58l40 80h92v-40h-68l-40-80H188l-57-100q-2-5-3.5-10t-1.5-10q0-4 5-20l57-100h104l40-80h68v-40h-92l-40 80h-58l78-139q5-10 14.5-15.5T323-800h97q17 0 28.5 11.5T460-760v160h-60l-40 40h100v120h-88l-40-80h-92l-40 40h108l40 80h112v200q0 17-11.5 28.5T420-160h-97Zm217 0q-17 0-28.5-11.5T500-200v-200h112l40-80h108l-40-40h-92l-40 80h-88v-120h100l-40-40h-60v-160q0-17 11.5-28.5T540-800h97q11 0 20.5 5.5T672-779l78 139h-58l-40-80h-92v40h68l40 80h104l57 100q2 5 3.5 10t1.5 10q0 4-5 20l-57 100H668l-40 80h-68v40h92l40-80h58l-78 139q-5 10-14.5 15.5T637-160h-97Z' />
      </svg>
    ),
    roboarm: (
      <svg {...attributes} xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'>
        <path d='M159-120v-120h124L181-574q-27-15-44.5-44T119-680q0-50 35-85t85-35q39 0 69.5 22.5T351-720h128v-40q0-17 11.5-28.5T519-800q9 0 17.5 4t14.5 12l68-64q9-9 21.5-11.5T665-856l156 72q12 6 16.5 17.5T837-744q-6 12-17.5 15.5T797-730l-144-66-94 88v56l94 86 144-66q11-5 23-1t17 15q6 12 1 23t-17 17l-156 74q-12 6-24.5 3.5T619-512l-68-64q-6 6-14.5 11t-17.5 5q-17 0-28.5-11.5T479-600v-40H351q-3 8-6.5 15t-9.5 15l200 370h144v120H159Zm80-520q17 0 28.5-11.5T279-680q0-17-11.5-28.5T239-720q-17 0-28.5 11.5T199-680q0 17 11.5 28.5T239-640Zm126 400h78L271-560h-4l98 320Zm78 0Z' />
      </svg>
    ),
    busy: (
      <svg {...attributes} xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'>
        <path d='M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z' />
      </svg>
    ),
    upArrow: (
      <svg {...attributes} xmlns='http://www.w3.org/2000/svg' height='16' viewBox='0 -960 960 960' width='16'>
        <path d='M440-160v-487L216-423l-56-57 320-320 320 320-56 57-224-224v487h-80Z' />
      </svg>
    ),
    downArrow: (
      <svg {...attributes} xmlns='http://www.w3.org/2000/svg' height='16' viewBox='0 -960 960 960' width='16'>
        <path d='M440-800v487L216-537l-56 57 320 320 320-320-56-57-224 224v-487h-80Z' />
      </svg>
    ),
    moveBottomRight: (
      <svg
        {...attributes}
        xmlns='http://www.w3.org/2000/svg'
        height='24px'
        viewBox='0 -960 960 960'
        width='24px'
        fill='#e8eaed'
      >
        <path d='M80-160v-80h720v-560h80v560q0 33-23.5 56.5T800-160H80Zm240-160v-320h400v320H320Z' />
      </svg>
    ),
    trash: (
      <svg {...attributes} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'>
        <path
          transform='translate(-0.65)'
          d='M 10.05 4.36 V 11.7 a 0.43 0.43 0 0 1 -0.85 0 V 4.36 H 7.69 V 11.7 a 0.42 0.42 0 0 1 -0.43 0.42 a 0.41 0.41 0 0 1 -0.42 -0.42 V 4.36 H 5.32 V 11.7 a 0.41 0.41 0 0 1 -0.42 0.42 a 0.42 0.42 0 0 1 -0.43 -0.42 V 4.36 H 3 v 8.85 a 0.8 0.8 0 0 0 0.79 0.79 H 10.6 a 0.8 0.8 0 0 0 0.78 -0.79 V 4.36 Z'
        />
        <path
          transform='translate(-0.65)'
          d='M 11.81 1.64 H 10.17 V 0.79 A 0.8 0.8 0 0 0 9.38 0 H 5.26 a 0.8 0.8 0 0 0 -0.79 0.79 v 0.85 H 2.84 a 0.8 0.8 0 0 0 -0.79 0.78 V 3 a 0.8 0.8 0 0 0 0.79 0.79 h 8.91 A 0.8 0.8 0 0 0 12.53 3 V 2.42 A 0.74 0.74 0 0 0 11.81 1.64 Z'
        />
      </svg>
    ),
    support: (
      <svg
        {...attributes}
        xmlns='http://www.w3.org/2000/svg'
        height='24px'
        viewBox='0 -960 960 960'
        width='24px'
        fill='#e8eaed'
      >
        <path d='M440-120v-80h320v-284q0-117-81.5-198.5T480-764q-117 0-198.5 81.5T200-484v244h-40q-33 0-56.5-23.5T80-320v-80q0-21 10.5-39.5T120-469l3-53q8-68 39.5-126t79-101q47.5-43 109-67T480-840q68 0 129 24t109 66.5Q766-707 797-649t40 126l3 52q19 9 29.5 27t10.5 38v92q0 20-10.5 38T840-249v49q0 33-23.5 56.5T760-120H440Zm-80-280q-17 0-28.5-11.5T320-440q0-17 11.5-28.5T360-480q17 0 28.5 11.5T400-440q0 17-11.5 28.5T360-400Zm240 0q-17 0-28.5-11.5T560-440q0-17 11.5-28.5T600-480q17 0 28.5 11.5T640-440q0 17-11.5 28.5T600-400Zm-359-62q-7-106 64-182t177-76q89 0 156.5 56.5T720-519q-91-1-167.5-49T435-698q-16 80-67.5 142.5T241-462Z' />
      </svg>
    ),
    error: (
      <svg
        {...attributes}
        xmlns='http://www.w3.org/2000/svg'
        height='24px'
        viewBox='0 -960 960 960'
        width='24px'
        fill='#e8eaed'
      >
        <path d='M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z' />
      </svg>
    ),
    chevronRight: (
      <svg
        {...attributes}
        xmlns='http://www.w3.org/2000/svg'
        height='24px'
        viewBox='0 -960 960 960'
        width='24px'
        fill='#e8eaed'
      >
        <path d='M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z' />
      </svg>
    ),
    refresh: (
      <svg
        {...attributes}
        xmlns='http://www.w3.org/2000/svg'
        height='24px'
        viewBox='0 -960 960 960'
        width='24px'
        fill='#e8eaed'
      >
        <path d='M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-110h80v280H520v-80h168q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h84q-28 106-114 173t-196 67Z' />
      </svg>
    ),
    hide: (
      <svg
        {...attributes}
        xmlns='http://www.w3.org/2000/svg'
        height='24px'
        viewBox='0 -960 960 960'
        width='24px'
        fill='#e8eaed'
      >
        <path d='m644-428-58-58q9-47-27-88t-93-32l-58-58q17-8 34.5-12t37.5-4q75 0 127.5 52.5T660-500q0 20-4 37.5T644-428Zm128 126-58-56q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-62-62q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302Zm20 246L624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM222-624q-29 26-53 57t-41 67q50 101 143.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z' />
      </svg>
    ),
    show: (
      <svg
        {...attributes}
        xmlns='http://www.w3.org/2000/svg'
        height='24px'
        viewBox='0 -960 960 960'
        width='24px'
        fill='#e8eaed'
      >
        <path d='M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z' />
      </svg>
    ),
    paste: (
      <svg
        {...attributes}
        xmlns='http://www.w3.org/2000/svg'
        height='24px'
        viewBox='0 -960 960 960'
        width='24px'
        fill='#e8eaed'
      >
        <path d='M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h167q11-35 43-57.5t70-22.5q40 0 71.5 22.5T594-840h166q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560h-80v120H280v-120h-80v560Zm280-560q17 0 28.5-11.5T520-800q0-17-11.5-28.5T480-840q-17 0-28.5 11.5T440-800q0 17 11.5 28.5T480-760Z' />
      </svg>
    ),
    copy: (
      <svg
        {...attributes}
        xmlns='http://www.w3.org/2000/svg'
        height='24px'
        viewBox='0 -960 960 960'
        width='24px'
        fill='#e8eaed'
      >
        <path d='M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360Zm0-80h360v-480H360v480ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Zm160-240v-480 480Z' />
      </svg>
    ),
    pasteCircle: (
      <svg
        {...attributes}
        enableBackground='0 0 14 14'
        height='14'
        preserveAspectRatio='none'
        width='14'
        x='0'
        y='0'
        viewBox='0 0 14 14'
        style={{ height: '23px', minWidth: '23px', width: '23px' }}
      >
        <g>
          <circle cx='7' cy='7' r='6' style={{ fill: 'rgb(112, 154, 181)' }} />
          <path
            style={{ fill: '#FFFFFF' }}
            d='M4.5,10.2c-0.2,0-0.4-0.1-0.5-0.2C3.9,9.9,3.8,9.7,3.8,9.5V4.6c0-0.2,0.1-0.4,0.2-0.5C4.2,4,4.4,3.9,4.5,3.9H6
	c0.1-0.2,0.2-0.4,0.4-0.5C6.6,3.3,6.8,3.2,7,3.2c0.2,0,0.4,0.1,0.6,0.2C7.8,3.5,7.9,3.7,8,3.9h1.5C9.6,3.9,9.8,4,10,4.1
	c0.1,0.1,0.2,0.3,0.2,0.5v4.9c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.3,0.2-0.5,0.2H4.5z M4.5,9.5h4.9V4.6H8.7v1.1H5.2V4.6H4.5V9.5z
	 M7,4.6c0.1,0,0.2,0,0.3-0.1s0.1-0.1,0.1-0.3c0-0.1,0-0.2-0.1-0.3C7.2,3.9,7.1,3.9,7,3.9S6.8,3.9,6.7,4S6.6,4.1,6.6,4.2
	c0,0.1,0,0.2,0.1,0.3C6.8,4.6,6.9,4.6,7,4.6z'
          />
        </g>
      </svg>
    ),

    mobileView: (
      <svg
        {...attributes}
        xmlns='http://www.w3.org/2000/svg'
        height='24px'
        viewBox='0 -960 960 960'
        width='24px'
        fill='#e8eaed'
      >
        <path d='M80-160v-120h80v-440q0-33 23.5-56.5T240-800h600v80H240v440h240v120H80Zm520 0q-17 0-28.5-11.5T560-200v-400q0-17 11.5-28.5T600-640h240q17 0 28.5 11.5T880-600v400q0 17-11.5 28.5T840-160H600Zm40-120h160v-280H640v280Zm0 0h160-160Z' />
      </svg>
    ),

    addCircle: (
      <svg
        {...attributes}
        enableBackground='0 0 14 14'
        height='14'
        preserveAspectRatio='none'
        width='14'
        x='0'
        xlinkHref='http://www.w3.org/1999/xlink'
        y='0'
        viewBox='0 0 14 14'
        style={{ height: '23px', minWidth: '23px', width: '23px' }}
      >
        <g>
          <circle cx='7' cy='7' r='6' style={{ fill: 'rgb(112, 154, 181)' }}></circle>
          <path
            d='M 6.44 9.42 V 7.56 H 4.52 v -1.3 h 1.92 V 4.4 h 1.24 v 1.86 H 9.6 v 1.3 H 7.68 v 1.86 H 6.44 Z'
            style={{ fill: 'rgb(255, 255, 255)' }}
          ></path>
        </g>
      </svg>
    ),
    close: (
      <svg {...attributes} version='1.1' xmlns='http://www.w3.org/2000/svg'>
        <line x1='1' y1='11' x2='11' y2='1' stroke='black' strokeWidth='2' />
        <line x1='1' y1='1' x2='11' y2='11' stroke='black' strokeWidth='2' />
      </svg>
    ),
    removeCircle: (
      <svg
        {...attributes}
        enableBackground='0 0 14 14'
        height='14'
        preserveAspectRatio='none'
        width='14'
        x='0'
        xlinkHref='http://www.w3.org/1999/xlink'
        y='0'
        viewBox='0 0 14 14'
        style={{ height: '23px', minWidth: '23px', width: '23px' }}
      >
        <g>
          <circle cx='7' cy='7' r='7' style={{ fill: 'rgb(112, 154, 181)' }}></circle>
          <path
            d='M 4.52 7.56 V 6.26 C 4.52 6.26 4.52 6.26 9.6 6.26 V 7.56 Z'
            style={{ fill: 'rgb(255, 255, 255)' }}
          ></path>
        </g>
      </svg>
    ),
  };

  return <div className='SVGIcon'>{icons[props.type] ?? null}</div>;
};

export default SVGIcon;
