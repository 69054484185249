import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AddSVG from '@/shared/helpers/icons';
import userDataHelper from '@/shared/helpers/user_data_helper';
import ActivityFeed from '@/main/components/ActivityFeed/ActivityFeed';
import NewCoolFeatures from '@/main/components/NewCoolFeatures/NewCoolFeatures';
import './Home.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setBreadcrumbs } from '@/main/redux/slices/features/breadcrumbsFeatureSlice';

const Home = (props) => {
  const [coursesLastEdited, setCoursesLastEdited] = useState([]);
  const profileData = useSelector((state) => state.profile.userSettingsData);
  const userData = userDataHelper.getUserData();
  const adminsClients = userDataHelper.getClientsWhereAdmin();
  const userClients = userDataHelper.getAllClients();
  const isSuperAdmin = userDataHelper.isSuperAdmin();

  console.log('profileData', profileData);

  const links = [
    {
      text: 'Courses',
      link: `/clients/${userData.clients && userData.clients[0] ? userData.clients[0].id : ''}`,
      svg: { icon: 'clients' },
      condition: !isSuperAdmin && userClients.length === 1,
    },
    {
      text: 'Clients',
      link: '/clients',
      svg: { icon: 'clients' },
      condition: isSuperAdmin || userClients.length > 1,
    },
    {
      text: 'Profile',
      link: '/profile',
      svg: { icon: 'profile' },
      condition: true,
    },
    {
      text: 'Languages',
      link: '/languages',
      svg: { icon: 'translate' },
      condition: isSuperAdmin || adminsClients.length > 0,
    },
    // {
    //     text:"Tutorials",
    //     link:"/tutorials",
    //     svg:{icon:"comp-video", "size": 110,"resize": 64,},
    // },
    {
      text: 'FAQs',
      link: '/faqs',
      svg: { icon: 'faq' },
      condition: true,
    },
    {
      text: 'Admin Tools',
      link: '/admin',
      svg: { icon: 'admin' },
      condition: isSuperAdmin,
    },
  ];

  const versionText = document.querySelector('.version-indicator').innerHTML;

  useEffect(() => {
    axios
      .get('/api/my-courses/')
      .then((res) => {
        const mapped = res.data.map((course) => {
          const urlEncodedTitle = encodeURIComponent(course.title);

          return {
            title: course.title,
            link: `/clients/${course.client_id}/courses/?search=${urlEncodedTitle}`,
          };
        });
        setCoursesLastEdited(mapped);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    //discard sessions
    axios.get('/api/destroymysessions');
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBreadcrumbs([{ text: 'Home', rrlink: '/home' }]));
  }, []);

  //   preload - in this case the /clients/ view
  useEffect(() => {
    if (props.preload) {
      props.preload();
    }
  }, []);

  return (
    <div className='Home'>
      <div className={'landing-page'}>
        <div className={'landing-sidebarcont'}>
          <div className='landing-logo' />
          <div className={'landing-sidebar'}>
            <h2>Welcome back, {userData.first_name}!</h2>
            {coursesLastEdited.length ? (
              <>
                <h3>Recently Updated Favorite Courses:</h3>
                <div className={'landing-courses'}>
                  {coursesLastEdited.map((entry, index) => {
                    return (
                      <Link key={entry.link + entry.title} to={entry.link}>
                        {entry.title}
                      </Link>
                    );
                  })}
                </div>
              </>
            ) : null}
            {profileData.new_features && <NewCoolFeatures />}
            {<ActivityFeed />}
          </div>
          <div className={'landing-versions'} dangerouslySetInnerHTML={{ __html: versionText }}></div>
        </div>
        <div className={'landing-contentcont'}>
          <div className={'landing-content'}>
            <div className={'actions'}>
              {links
                .filter((link) => link.condition)
                .map((link, linkIndex) => {
                  const size = link.svg.icon == 'translate' ? 38 : 72;
                  if (link.anchor) {
                    return (
                      <a key={link.link + link.text} href={link.anchor}>
                        <div className={'link-card'}>
                          <div className={'link-icon'}>
                            <AddSVG size={size} resize={72} fill={'#47B058'} {...link.svg} />
                          </div>
                          <div className={'link-title'}>
                            <div>{link.text}</div>
                          </div>
                        </div>
                      </a>
                    );
                  }

                  return (
                    <Link key={link.link + link.text} to={link.link}>
                      <div className={'link-card'}>
                        <div className={'link-icon'}>
                          <AddSVG
                            size={size}
                            resize={72}
                            fill={'#47B058'}
                            pathFill={link.svg.icon == 'translate' ? '#47B058' : ''}
                            {...link.svg}
                          />
                        </div>
                        <div className={'link-title'}>
                          <div>{link.text}</div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
            </div>
            <Link className={'landing-btn'} to={'/changelog'}>
              View Changelog
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
