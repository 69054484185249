import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AccordionButton from '@/shared/components/AccordionButton/AccordionButton';

const cb = 'cml-accordion-section';

class CMLAccordionSection extends Component {
  onClick = () => {
    this.props.onClick(this.props.label);
  };

  render() {
    const {
      onClick,
      props: { isOpen, label },
    } = this;
    const openClass = isOpen ? `${cb}--open` : '';

    return (
      <div className={`${cb} ${openClass}`}>
        <div
          className={`${cb}__title` + (this.props.disabled ? ' disabled' : '')}
          onClick={onClick}
          onKeyPress={onClick}
          tabIndex={0}
        >
          <div className={`${cb}__label`}>{label}</div>
          <div className={`${cb}__icon`}>
            <AccordionButton val={isOpen} visualOnly={true} />
          </div>
        </div>
        <div className={`${cb}__content`}>
          <div className={`${cb}__content-inner`}>{isOpen ? this.props.children : null}</div>
        </div>
      </div>
    );
  }
}

CMLAccordionSection.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  isOpen: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
CMLAccordionSection.defaultProps = {
  children: {},
  onClick: () => {},
};

export default CMLAccordionSection;
