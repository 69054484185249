import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import config from '@/config';

// Async thunk to fetch course favorites
export const fetchFavorites = createAsyncThunk('favorites/fetchFavorites', async(_, thunkAPI) => {
  try {
    const response = await axios.get('/api/favorites/');

    return response.data;
  } catch (error) {
    console.error('fetchFavorites error', error);

    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

// toggle course favorite
export const toggleCourseFavorite = createAsyncThunk('favorites/toggleCourseFavorite', async(courseID, thunkAPI) => {
  try {
    const response = await axios.post('/api/favorites/', {
      course_id: courseID,
    });

    return response.data;
  } catch (error) {
    console.error('toggleCourseFavorite error', error);

    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

// toggle client favorite
export const toggleClientFavorite = createAsyncThunk('favorites/toggleClientFavorite', async(clientID, thunkAPI) => {
  try {
    const response = await axios.post('/api/favorites/', {
      client_id: clientID,
    });

    return response.data;
  } catch (error) {
    console.error('toggleClientFavorite error', error);

    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

const favoritesSlice = createSlice({
  name: 'favorites',
  initialState: {
    course_ids: [],
    client_ids: [],
    loading: false,
    error: null,
  },
  reducers: {
    // Add synchronous actions if needed
  },
  extraReducers: (builder) => {
    // Handle fetchFavorites
    builder
      .addCase(fetchFavorites.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFavorites.fulfilled, (state, action) => {
        state.loading = false;
        state.course_ids = action.payload.course_ids;
        state.client_ids = action.payload.client_ids;
      })
      .addCase(fetchFavorites.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Handle toggleCourseFavorite
    builder
      .addCase(toggleCourseFavorite.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(toggleCourseFavorite.fulfilled, (state, action) => {
        state.loading = false;
        state.course_ids = action.payload.course_ids;
        state.client_ids = action.payload.client_ids;
      })
      .addCase(toggleCourseFavorite.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Handle toggleClientFavorite
    builder
      .addCase(toggleClientFavorite.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(toggleClientFavorite.fulfilled, (state, action) => {
        state.loading = false;
        state.course_ids = action.payload.course_ids;
        state.client_ids = action.payload.client_ids;
      })
      .addCase(toggleClientFavorite.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default favoritesSlice.reducer;
